import * as Sentry from '@sentry/browser';
import { commonSentryConfig } from './common-sentry-config';

Sentry.init({
  ...commonSentryConfig,
  tracesSampleRate: parseFloat(process.env.SENTRY_TRACES_SAMPLE_RATE),
  tracePropagationTargets: [
    'healthlabs.com',
    'healthlabs.local',
    'www.healthlabs.com',
    'www.healthlabs.local',
    'checkout.healthlabs.com',
  ],
  integrations: [Sentry.browserTracingIntegration()],
  ignoreErrors: ['BraintreeError: Cannot contact the gateway at this time.'],
});
