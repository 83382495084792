/**
 * Common Sentry configuration for HL pages and ng-checkout.
 */
export const commonSentryConfig = {
    environment: process.env.SENTRY_ENVIRONMENT,
    dsn: 'https://9a6f2e2dd0324a6e898b1a275d0afb88@o510915.ingest.us.sentry.io/5607267',
    release: process.env.SENTRY_RELEASE,
    sampleRate: parseFloat(process.env.SENTRY_SAMPLE_RATE),
    beforeSend(event) {
        event.tags = {
            ...event.tags,
            order_id: localStorage.getItem('transaction_id') ?? null,
        };

        return event;
    },
};
